import AdjustmentsProvider from '@providers/AdjustmentsProvider';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDto } from '@models/interfaces/dtos/IAdjustmentDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import mapper from '@models/mapper';

const adjustmentsProvider = new AdjustmentsProvider();

export const getAdjustments = async (
  url: string,
  operation: string,
  categoryId?: string,
  type?: number,
) => {
  const result = await adjustmentsProvider.getAdjustments(url, operation, categoryId, type);
  return {
    operation: result.operation,
    items: mapper.mapArray<IAdjustmentDto, IAdjustment>(
      result.items,
      'IAdjustmentDto',
      'IAdjustment',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};
