import AdjustmentDefinitionsProvider from '@providers/AdjustmentDefinitionsProvider';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IAdjustmentDefinitionDto } from '@models/interfaces/dtos/IAdjustmentDefinitionDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import mapper from '@models/mapper';

const adjustmentDefinitionsProvider = new AdjustmentDefinitionsProvider();

export const getAdjustmentDefinitions = async (
  url: string,
  operation: string,
  categoryId?: string,
  type?: number,
) => {
  const result = await adjustmentDefinitionsProvider.getAdjustmentDefinitions(
    url,
    operation,
    categoryId,
    type,
  );
  return {
    operation: result.operation,
    items: mapper.mapArray<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
      result.items,
      'IAdjustmentDefinitionDto',
      'IAdjustmentDefinition',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};
