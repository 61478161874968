import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { getAdjustments } from '@services/api';
import useStyles from './styles';

enum AdjustmentOperation {
  Initial = 'initial',
  Manual = 'manual',
  Add = 'add',
  Subtract = 'subtract',
  Match = 'match',
  Fees = 'fees',
}

interface IAccountBalance {
  accountId: string;
  accountName: string;
  sourceBalance: number;
  manualAdjustment: number;
  glAdjustment: number;
  fees: number;
  totalBalance: number;
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  type: number;
}

export const BalancingItemContent = ({ project, accounts, type }: IProps) => {
  const { classes } = useStyles();

  const [initialAdjustments, setInitialAdjustments] = useState<IAdjustment[]>([]);
  const [manualAdjustments, setManualAdjustments] = useState<IAdjustment[]>([]);
  const [glAddAdjustments, setGlAddAdjustments] = useState<IAdjustment[]>([]);
  const [glSubtractAdjustments, setGlSubtractAdjustments] = useState<IAdjustment[]>([]);
  const [glMatchAdjustments, setGlMatchAdjustments] = useState<IAdjustment[]>([]);
  const [feesAdjustments, setFeesAdjustments] = useState<IAdjustment[]>([]);

  const {
    request: getAdjustmentsRequest,
    data: getAdjustmentsData,
    loading: getAdjustmentsLoading,
  } = useApi(getAdjustments, null, { handleErrors: true });

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          field: 'accountName',
          headerName: 'Account',
          type: 'string',
          width: 155,
        },
        {
          field: 'sourceBalance',
          headerName: 'Source Balance',
          type: 'number',
          width: 155,
          renderCell: (params) => getBalanceLabel(params.value),
        },
        {
          field: 'manualAdjustment',
          headerName: 'Manual Adjustment',
          type: 'number',
          width: 155,
          renderCell: (params) => getBalanceLabel(params.value),
        },
        {
          field: 'glAdjustment',
          headerName: 'GL Adjustment',
          type: 'number',
          width: 155,
          renderCell: (params) => getBalanceLabel(params.value),
        },
        {
          field: 'fees',
          headerName: 'Fees',
          type: 'number',
          width: 155,
          renderCell: (params) => getBalanceLabel(params.value),
        },
        {
          field: 'totalBalance',
          headerName: 'Total Balance',
          type: 'number',
          width: 155,
          renderCell: (params) => getBalanceLabel(params.value),
        },
      ] as GridColDef<IAccountBalance>[],
    [],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustments]) {
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Initial,
        undefined,
        type,
      );
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Manual,
        undefined,
        type,
      );
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Add,
        undefined,
        type,
      );
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Subtract,
        undefined,
        type,
      );
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Match,
        undefined,
        type,
      );
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        AdjustmentOperation.Fees,
        undefined,
        type,
      );
    }
  }, [project.links[Actions.getAdjustments]?.href]);

  useUpdateEffect(() => {
    if (getAdjustmentsData) {
      switch (getAdjustmentsData.operation) {
        case AdjustmentOperation.Initial:
          setInitialAdjustments(getAdjustmentsData.items);
          break;
        case AdjustmentOperation.Manual:
          setManualAdjustments(getAdjustmentsData.items);
          break;
        case AdjustmentOperation.Add:
          setGlAddAdjustments(getAdjustmentsData.items);
          break;
        case AdjustmentOperation.Subtract:
          setGlSubtractAdjustments(getAdjustmentsData.items);
          break;
        case AdjustmentOperation.Match:
          setGlMatchAdjustments(getAdjustmentsData.items);
          break;
        case AdjustmentOperation.Fees:
          setFeesAdjustments(getAdjustmentsData.items);
          break;
      }
    }
  }, [getAdjustmentsData]);

  const filteredAccounts = useMemo(
    () => accounts.filter((x) => x.accountType.type === type),
    [accounts],
  );

  const accountBalances = useMemo<IAccountBalance[]>(
    () =>
      filteredAccounts.map((x) => {
        const sourceBalance = initialAdjustments
          .filter((a) => a.accountId === x.id)
          .reduce((sum, a) => sum + a.amount, 0);
        const manualAdjustment = manualAdjustments
          .filter((a) => a.accountId === x.id)
          .reduce((sum, a) => sum + a.amount, 0);
        const glAdjustment = [...glAddAdjustments, ...glSubtractAdjustments, ...glMatchAdjustments]
          .filter((a) => a.accountId === x.id)
          .reduce((sum, a) => sum + a.amount, 0);
        const fees = feesAdjustments
          .filter((a) => a.accountId === x.id)
          .reduce((sum, a) => sum + a.amount, 0);
        return {
          accountId: x.id,
          accountName: x.accountName,
          sourceBalance,
          manualAdjustment,
          glAdjustment,
          fees,
          totalBalance: sourceBalance + manualAdjustment + glAdjustment + fees,
        };
      }),
    [
      accounts,
      initialAdjustments,
      manualAdjustments,
      glAddAdjustments,
      glSubtractAdjustments,
      glMatchAdjustments,
      feesAdjustments,
    ],
  );

  const showLoader = useLoader(getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={accountBalances}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'accountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                sourceBalance: 'sum',
                manualAdjustment: 'sum',
                glAdjustment: 'sum',
                fees: 'sum',
                totalBalance: 'sum',
              },
            },
          }}
          slots={{
            footer: () => null,
          }}
          getRowId={(row) => row.accountId}
        />
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
