import { Mapper, createMap } from '@automapper/core';

import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDto } from '@models/interfaces/dtos/IAdjustmentDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createAdjustmentMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IAdjustmentDto>('IAdjustmentDto', {
    id: String,
    amount: Number,
    sourceAdjustment: Number,
    adjustmentDefinitionId: String,
    accountId: String,
  });

  PojosMetadataMap.create<IAdjustment>('IAdjustment', {
    id: String,
    amount: Number,
    sourceAdjustment: Number,
    adjustmentDefinitionId: String,
    accountId: String,
  });

  createMap<IAdjustmentDto, IAdjustment>(mapper, 'IAdjustmentDto', 'IAdjustment');
};
