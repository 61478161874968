import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {},
  table: {
    maxHeight: 300,
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-virtualScroller': {
      minHeight: 52,
    },

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="accountName"]': {
        '&::after': {
          content: '"Total"',
          display: 'block',
        },
      },
    },
  },
}));

export default useStyles;
