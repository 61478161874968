import BaseProvider from './BaseProvider';
import { IGetAdjustmentsResponse } from '@models/interfaces/responses/IGetAdjustmentsResponse';

export default class AdjustmentsProvider extends BaseProvider {
  public getAdjustments = async (
    url: string,
    operation: string,
    categoryId?: string,
    type?: number,
  ) => {
    return this.get<IGetAdjustmentsResponse>(url, {
      params: {
        operation,
        categoryId,
        type,
      },
    });
  };
}
