import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IAddGroupMemberData } from '@models/interfaces/additional/IAddGroupMemberData';
import { IAddGroupMemberRequest } from '@models/interfaces/requests/IAddGroupMemberRequest';
import { IAddUserMembershipData } from '@models/interfaces/additional/IAddUserMembershipData';
import { IAddUserMembershipRequest } from '@models/interfaces/requests/IAddUserMembershipRequest';
import { IChangeFileCategoryData } from '@models/interfaces/additional/IChangeFileCategoryData';
import { IChangeFileCategoryRequest } from '@models/interfaces/requests/IChangeFileCategoryRequest';
import { IChangeFileSubCategoryMappingData } from '@models/interfaces/additional/IChangeFileSubCategoryMappingData';
import { IChangeFileSubCategoryMappingRequest } from '@models/interfaces/requests/IChangeFileSubCategoryMappingRequest';
import { IChangeFileSubCategoryTransformationScriptData } from '@models/interfaces/additional/IChangeFileSubCategoryTransformationScriptData';
import { IChangeFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/IChangeFileSubCategoryTransformationScriptRequest';
import { ICreateClientData } from '@models/interfaces/additional/ICreateClientData';
import { ICreateClientFeatureData } from '@models/interfaces/additional/ICreateClientFeatureData';
import { ICreateClientFeatureRequest } from '@models/interfaces/requests/ICreateClientFeatureRequest';
import { ICreateClientProductData } from '@models/interfaces/additional/ICreateClientProductData';
import { ICreateClientProductRequest } from '@models/interfaces/requests/ICreateClientProductRequest';
import { ICreateClientRequest } from '@models/interfaces/requests/ICreateClientRequest';
import { ICreateFileCategoryData } from '@models/interfaces/additional/ICreateFileCategoryData';
import { ICreateFileCategoryFieldData } from '@models/interfaces/additional/ICreateFileCategoryFieldData';
import { ICreateFileCategoryFieldRequest } from '@models/interfaces/requests/ICreateFileCategoryFieldRequest';
import { ICreateFileCategoryRequest } from '@models/interfaces/requests/ICreateFileCategoryRequest';
import { ICreateFileSubCategoryData } from '@models/interfaces/additional/ICreateFileSubCategoryData';
import { ICreateFileSubCategoryMappingData } from '@models/interfaces/additional/ICreateFileSubCategoryMappingData';
import { ICreateFileSubCategoryMappingRequest } from '@models/interfaces/requests/ICreateFileSubCategoryMappingRequest';
import { ICreateFileSubCategoryRequest } from '@models/interfaces/requests/ICreateFileSubCategoryRequest';
import { ICreateFileSubCategoryTransformationScriptData } from '@models/interfaces/additional/ICreateFileSubCategoryTransformationScriptData';
import { ICreateFileSubCategoryTransformationScriptRequest } from '@models/interfaces/requests/ICreateFileSubCategoryTransformationScriptRequest';
import { ICreateGroupData } from '@models/interfaces/additional/ICreateGroupData';
import { ICreateGroupRequest } from '@models/interfaces/requests/ICreateGroupRequest';
import { ICreatePiiIncidentCategoryData } from '@models/interfaces/additional/ICreatePiiIncidentCategoryData';
import { ICreatePiiIncidentCategoryRequest } from '@models/interfaces/requests/ICreatePiiIncidentCategoryRequest';
import { ICreateProductData } from '@models/interfaces/additional/ICreateProductData';
import { ICreateProductRequest } from '@models/interfaces/requests/ICreateProductRequest';
import { ICreateProjectData } from '@models/interfaces/additional/ICreateProjectData';
import { ICreateProjectRequest } from '@models/interfaces/requests/ICreateProjectRequest';
import { ICreateProjectResultsUploadedNotificationData } from '@models/interfaces/additional/ICreateProjectResultsUploadedNotificationData';
import { ICreateProjectResultsUploadedNotificationRequest } from '@models/interfaces/requests/ICreateProjectResultsUploadedNotificationRequest';
import { ICreateUserData } from '@models/interfaces/additional/ICreateUserData';
import { ICreateUserRequest } from '@models/interfaces/requests/ICreateUserRequest';
import { ICreateWhitelistRecordData } from '@models/interfaces/additional/ICreateWhitelistRecordData';
import { ICreateWhitelistRecordRequest } from '@models/interfaces/requests/ICreateWhitelistRecordRequest';
import { IUpdateAccountsDataAccount } from '@models/interfaces/additional/IUpdateAccountsData';
import { IUpdateAccountsRequestAccount } from '@models/interfaces/requests/IUpdateAccountsRequest';
import { IUpdateClientData } from '@models/interfaces/additional/IUpdateClientData';
import { IUpdateClientRequest } from '@models/interfaces/requests/IUpdateClientRequest';
import { IUpdateClientRolesDataRole } from '@models/interfaces/additional/IUpdateClientRolesData';
import { IUpdateClientRolesRequestRole } from '@models/interfaces/requests/IUpdateClientRolesRequest';
import { IUpdateFeatureData } from '@models/interfaces/additional/IUpdateFeatureData';
import { IUpdateFeatureRequest } from '@models/interfaces/requests/IUpdateFeatureRequest';
import { IUpdateFileCategoriesOrderData } from '@models/interfaces/additional/IUpdateFileCategoriesOrderData';
import { IUpdateFileCategoriesOrderRequest } from '@models/interfaces/requests/IUpdateFileCategoriesOrderRequest';
import { IUpdateFileCategoryData } from '@models/interfaces/additional/IUpdateFileCategoryData';
import { IUpdateFileCategoryFieldData } from '@models/interfaces/additional/IUpdateFileCategoryFieldData';
import { IUpdateFileCategoryFieldRequest } from '@models/interfaces/requests/IUpdateFileCategoryFieldRequest';
import { IUpdateFileCategoryRequest } from '@models/interfaces/requests/IUpdateFileCategoryRequest';
import { IUpdateFileSubCategoryData } from '@models/interfaces/additional/IUpdateFileSubCategoryData';
import { IUpdateFileSubCategoryMappingData } from '@models/interfaces/additional/IUpdateFileSubCategoryMappingData';
import { IUpdateFileSubCategoryMappingRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryMappingRequest';
import { IUpdateFileSubCategoryRequest } from '@models/interfaces/requests/IUpdateFileSubCategoryRequest';
import { IUpdateFileUserStatusData } from '@models/interfaces/additional/IUpdateFileUserStatusData';
import { IUpdateFileUserStatusRequest } from '@models/interfaces/requests/IUpdateFileUserStatusRequest';
import { IUpdateNewTypeCodeData } from '@models/interfaces/additional/IUpdateNewTypeCodeData';
import { IUpdateNewTypeCodeRequest } from '@models/interfaces/requests/IUpdateNewTypeCodeRequest';
import { IUpdatePiiIncidentCategoryData } from '@models/interfaces/additional/IUpdatePiiIncidentCategoryData';
import { IUpdatePiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdatePiiIncidentCategoryRequest';
import { IUpdateProductData } from '@models/interfaces/additional/IUpdateProductData';
import { IUpdateProductRequest } from '@models/interfaces/requests/IUpdateProductRequest';
import { IUpdateProjectData } from '@models/interfaces/additional/IUpdateProjectData';
import { IUpdateProjectRequest } from '@models/interfaces/requests/IUpdateProjectRequest';
import { IUpdateStandardPiiIncidentCategoryData } from '@models/interfaces/additional/IUpdateStandardPiiIncidentCategoryData';
import { IUpdateStandardPiiIncidentCategoryRequest } from '@models/interfaces/requests/IUpdateStandardPiiIncidentCategoryRequest';
import { IUpdateSubAccountsData } from '@models/interfaces/additional/IUpdateSubAccountsData';
import { IUpdateSubAccountsRequest } from '@models/interfaces/requests/IUpdateSubAccountsRequest';
import { IUpdateWhitelistRecordData } from '@models/interfaces/additional/IUpdateWhitelistRecordData';
import { IUpdateWhitelistRecordRequest } from '@models/interfaces/requests/IUpdateWhitelistRecordRequest';
import { PojosMetadataMap } from '@automapper/pojos';

export const createRequestMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ICreateClientProductData>('ICreateClientProductData', {
    productId: String,
  });

  PojosMetadataMap.create<ICreateClientProductRequest>('ICreateClientProductRequest', {
    productId: String,
  });

  PojosMetadataMap.create<ICreateClientFeatureData>('ICreateClientFeatureData', {
    featureId: String,
    clientId: String,
  });

  PojosMetadataMap.create<ICreateClientFeatureRequest>('ICreateClientFeatureRequest', {
    featureId: String,
    clientId: String,
  });

  PojosMetadataMap.create<IAddGroupMemberData>('IAddGroupMemberData', {
    userId: String,
  });

  PojosMetadataMap.create<IAddGroupMemberRequest>('IAddGroupMemberRequest', {
    userId: String,
  });

  PojosMetadataMap.create<IAddUserMembershipData>('IAddUserMembershipData', {
    groupId: String,
  });

  PojosMetadataMap.create<IAddUserMembershipRequest>('IAddUserMembershipRequest', {
    groupId: String,
  });

  PojosMetadataMap.create<ICreateClientData>('ICreateClientData', {
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
    access: [Object],
  });

  PojosMetadataMap.create<ICreateClientRequest>('ICreateClientRequest', {
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
    access: [Object],
  });

  PojosMetadataMap.create<ICreateProductData>('ICreateProductData', {
    name: String,
    url: String,
  });

  PojosMetadataMap.create<ICreateProductRequest>('ICreateProductRequest', {
    name: String,
    url: String,
  });

  PojosMetadataMap.create<ICreateGroupData>('ICreateGroupData', {
    name: String,
    description: String,
  });

  PojosMetadataMap.create<ICreateGroupRequest>('ICreateGroupRequest', {
    name: String,
    description: String,
  });

  PojosMetadataMap.create<ICreateUserData>('ICreateUserData', {
    firstName: String,
    lastName: String,
    email: String,
    companyName: String,
    phone: String,
  });

  PojosMetadataMap.create<ICreateUserRequest>('ICreateUserRequest', {
    firstName: String,
    lastName: String,
    email: String,
    companyName: String,
    phone: String,
  });

  PojosMetadataMap.create<ICreatePiiIncidentCategoryData>('ICreatePiiIncidentCategoryData', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<ICreatePiiIncidentCategoryRequest>('ICreatePiiIncidentCategoryRequest', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<ICreateWhitelistRecordData>('ICreateWhitelistRecordData', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<ICreateWhitelistRecordRequest>('ICreateWhitelistRecordRequest', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<ICreateFileCategoryData>('ICreateFileCategoryData', {
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<ICreateFileCategoryRequest>('ICreateFileCategoryRequest', {
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<ICreateFileCategoryFieldData>('ICreateFileCategoryFieldData', {
    name: String,
    displayName: String,
    type: String,
    required: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<ICreateFileCategoryFieldRequest>('ICreateFileCategoryFieldRequest', {
    name: String,
    displayName: String,
    type: String,
    required: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<ICreateFileSubCategoryData>('ICreateFileSubCategoryData', {
    notes: String,
    categoryId: String,
  });

  PojosMetadataMap.create<ICreateFileSubCategoryRequest>('ICreateFileSubCategoryRequest', {
    notes: String,
    categoryId: String,
  });

  PojosMetadataMap.create<ICreateFileSubCategoryMappingData>('ICreateFileSubCategoryMappingData', {
    name: String,
    delimiter: String,
    headerStartRow: Number,
    headerEndRow: Number,
    subAccountFormat: [String],
    delinquentLoanThreshold: Number,
    records: [Object],
  });

  PojosMetadataMap.create<ICreateFileSubCategoryMappingRequest>(
    'ICreateFileSubCategoryMappingRequest',
    {
      name: String,
      delimiter: String,
      headerStartRow: Number,
      headerEndRow: Number,
      subAccountFormat: [String],
      delinquentLoanThreshold: Number,
      records: [Object],
    },
  );

  PojosMetadataMap.create<ICreateFileSubCategoryTransformationScriptData>(
    'ICreateFileSubCategoryTransformationScriptData',
    {
      script: String,
    },
  );

  PojosMetadataMap.create<ICreateFileSubCategoryTransformationScriptRequest>(
    'ICreateFileSubCategoryTransformationScriptRequest',
    {
      script: String,
    },
  );

  PojosMetadataMap.create<IUpdateClientData>('IUpdateClientData', {
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
  });

  PojosMetadataMap.create<IUpdateClientRequest>('IUpdateClientRequest', {
    name: String,
    accountNumber: String,
    externalId: String,
    addressState: String,
    institutionType: String,
  });

  PojosMetadataMap.create<IUpdateClientRolesDataRole>('IUpdateClientRolesDataRole', {
    role: String,
    adGroup: String,
  });

  PojosMetadataMap.create<IUpdateClientRolesRequestRole>('IUpdateClientRolesRequestRole', {
    role: String,
    adGroup: String,
  });

  PojosMetadataMap.create<ICreateProjectData>('ICreateProjectData', {
    name: String,
    startDate: Date,
  });

  PojosMetadataMap.create<ICreateProjectRequest>('ICreateProjectRequest', {
    name: String,
    startDate: Number,
  });

  PojosMetadataMap.create<IUpdateFileUserStatusData>('IUpdateFileUserStatusData', {
    status: String,
  });

  PojosMetadataMap.create<IUpdateFileUserStatusRequest>('IUpdateFileUserStatusRequest', {
    status: String,
  });

  PojosMetadataMap.create<IChangeFileCategoryData>('IChangeFileCategoryData', {
    categoryId: String,
    subCategoryId: String,
  });

  PojosMetadataMap.create<IChangeFileCategoryRequest>('IChangeFileCategoryRequest', {
    categoryId: String,
    subCategoryId: String,
  });

  PojosMetadataMap.create<IChangeFileSubCategoryMappingData>('IChangeFileSubCategoryMappingData', {
    mappingId: String,
  });

  PojosMetadataMap.create<IChangeFileSubCategoryMappingRequest>(
    'IChangeFileSubCategoryMappingRequest',
    {
      mappingId: String,
    },
  );

  PojosMetadataMap.create<IChangeFileSubCategoryTransformationScriptData>(
    'IChangeFileSubCategoryTransformationScriptData',
    {
      transformationScriptId: String,
    },
  );

  PojosMetadataMap.create<IChangeFileSubCategoryTransformationScriptRequest>(
    'IChangeFileSubCategoryTransformationScriptRequest',
    {
      transformationScriptId: String,
    },
  );

  PojosMetadataMap.create<IUpdateProductData>('IUpdateProductData', {
    name: String,
    url: String,
  });

  PojosMetadataMap.create<IUpdateProductRequest>('IUpdateProductRequest', {
    name: String,
    url: String,
  });

  PojosMetadataMap.create<IUpdateFeatureData>('IUpdateFeatureData', {
    description: String,
  });

  PojosMetadataMap.create<IUpdateFeatureRequest>('IUpdateFeatureRequest', {
    description: String,
  });

  PojosMetadataMap.create<IUpdateProjectData>('IUpdateProjectData', {
    name: String,
    startDate: Date,
  });

  PojosMetadataMap.create<IUpdateProjectRequest>('IUpdateProjectRequest', {
    name: String,
    startDate: Number,
  });

  PojosMetadataMap.create<IUpdatePiiIncidentCategoryData>('IUpdatePiiIncidentCategoryData', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<IUpdatePiiIncidentCategoryRequest>('IUpdatePiiIncidentCategoryRequest', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<IUpdateWhitelistRecordData>('IUpdateWhitelistRecordData', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<IUpdateWhitelistRecordRequest>('IUpdateWhitelistRecordRequest', {
    name: String,
    template: String,
  });

  PojosMetadataMap.create<IUpdateStandardPiiIncidentCategoryData>(
    'IUpdateStandardPiiIncidentCategoryData',
    {
      enabled: Boolean,
      confidence: Number,
    },
  );

  PojosMetadataMap.create<IUpdateStandardPiiIncidentCategoryRequest>(
    'IUpdateStandardPiiIncidentCategoryRequest',
    {
      enabled: Boolean,
      confidence: Number,
    },
  );

  PojosMetadataMap.create<IUpdateFileCategoryData>('IUpdateFileCategoryData', {
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<IUpdateFileCategoryRequest>('IUpdateFileCategoryRequest', {
    name: String,
    description: String,
    subCategoriesDisabled: Boolean,
    mappingsDisabled: Boolean,
    subAccountMatchingDisabled: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<IUpdateFileCategoryFieldData>('IUpdateFileCategoryFieldData', {
    name: String,
    displayName: String,
    type: String,
    required: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<IUpdateFileCategoryFieldRequest>('IUpdateFileCategoryFieldRequest', {
    name: String,
    displayName: String,
    type: String,
    required: Boolean,
    position: Number,
  });

  PojosMetadataMap.create<IUpdateFileSubCategoryData>('IUpdateFileSubCategoryData', {
    notes: String,
    disabled: String,
  });

  PojosMetadataMap.create<IUpdateFileSubCategoryRequest>('IUpdateFileSubCategoryRequest', {
    notes: String,
    disabled: String,
  });

  PojosMetadataMap.create<IUpdateFileSubCategoryMappingData>('IUpdateFileSubCategoryMappingData', {
    name: String,
    delimiter: String,
    headerStartRow: Number,
    headerEndRow: Number,
    subAccountFormat: [String],
    delinquentLoanThreshold: Number,
    records: [Object],
  });

  PojosMetadataMap.create<IUpdateFileSubCategoryMappingRequest>(
    'IUpdateFileSubCategoryMappingRequest',
    {
      name: String,
      delimiter: String,
      headerStartRow: Number,
      headerEndRow: Number,
      subAccountFormat: [String],
      delinquentLoanThreshold: Number,
      records: [Object],
    },
  );

  PojosMetadataMap.create<ICreateProjectResultsUploadedNotificationData>(
    'ICreateProjectResultsUploadedNotificationData',
    {
      message: String,
      userIds: [String],
    },
  );

  PojosMetadataMap.create<ICreateProjectResultsUploadedNotificationRequest>(
    'ICreateProjectResultsUploadedNotificationRequest',
    {
      message: String,
      userIds: [String],
    },
  );

  PojosMetadataMap.create<IUpdateFileCategoriesOrderData>('IUpdateFileCategoriesOrderData', {
    records: [Object],
  });

  PojosMetadataMap.create<IUpdateFileCategoriesOrderRequest>('IUpdateFileCategoriesOrderRequest', {
    records: [Object],
  });

  PojosMetadataMap.create<IUpdateAccountsDataAccount>('IUpdateAccountsDataAccount', {
    fileId: String,
    accountName: String,
    accountCategory: String,
    sumCode: Number,
    downloadId: String,
    accountType: Number,
    detailAccounts: ['IUpdateAccountsDataAccount'],
  });

  PojosMetadataMap.create<IUpdateAccountsRequestAccount>('IUpdateAccountsRequestAccount', {
    fileId: String,
    accountName: String,
    accountCategory: String,
    sumCode: Number,
    downloadId: String,
    accountType: Number,
    detailAccounts: ['IUpdateAccountsRequestAccount'],
  });

  PojosMetadataMap.create<IUpdateSubAccountsData>('IUpdateSubAccountsData', {
    GLAccounts: ['IUpdateSubAccountsDataGLAccount'],
    GLTotalAccounts: ['IUpdateSubAccountsDataGLAccount'],
    DMAccounts: ['IUpdateSubAccountsDataDMAccount'],
    DMTotalAccounts: ['IUpdateSubAccountsDataDMAccount'],
    LoanPushBalancing: [String],
    InvestmentPushBalancing: [String],
    AssetPushBalancing: [String],
    SharePushBalancing: [String],
    CDPushBalancing: [String],
    BorrowingPushBalancing: [String],
    LiabilityPushBalancing: [String],
  });

  PojosMetadataMap.create<IUpdateSubAccountsRequest>('IUpdateSubAccountsRequest', {
    GLAccounts: ['IUpdateSubAccountsRequestGLAccount'],
    GLTotalAccounts: ['IUpdateSubAccountsRequestGLAccount'],
    DMAccounts: ['IUpdateSubAccountsRequestDMAccount'],
    DMTotalAccounts: ['IUpdateSubAccountsRequestDMAccount'],
    LoanPushBalancing: [String],
    InvestmentPushBalancing: [String],
    AssetPushBalancing: [String],
    SharePushBalancing: [String],
    CDPushBalancing: [String],
    BorrowingPushBalancing: [String],
    LiabilityPushBalancing: [String],
  });

  PojosMetadataMap.create<IUpdateNewTypeCodeData>('IUpdateNewTypeCodeData', {
    code: String,
    description: String,
    ignoreReason: String,
    isUsed: Boolean,
  });

  PojosMetadataMap.create<IUpdateNewTypeCodeRequest>('IUpdateNewTypeCodeRequest', {
    code: String,
    description: String,
    ignoreReason: String,
    isUsed: Boolean,
  });

  createMap<ICreateClientProductData, ICreateClientProductRequest>(
    mapper,
    'ICreateClientProductData',
    'ICreateClientProductRequest',
  );

  createMap<ICreateClientFeatureData, ICreateClientFeatureRequest>(
    mapper,
    'ICreateClientFeatureData',
    'ICreateClientFeatureRequest',
  );

  createMap<IAddGroupMemberData, IAddGroupMemberRequest>(
    mapper,
    'IAddGroupMemberData',
    'IAddGroupMemberRequest',
  );

  createMap<IAddUserMembershipData, IAddUserMembershipRequest>(
    mapper,
    'IAddUserMembershipData',
    'IAddUserMembershipRequest',
  );

  createMap<ICreateClientData, ICreateClientRequest>(
    mapper,
    'ICreateClientData',
    'ICreateClientRequest',
  );

  createMap<ICreateGroupData, ICreateGroupRequest>(
    mapper,
    'ICreateGroupData',
    'ICreateGroupRequest',
  );

  createMap<ICreateProductData, ICreateProductRequest>(
    mapper,
    'ICreateProductData',
    'ICreateProductRequest',
  );

  createMap<ICreateUserData, ICreateUserRequest>(mapper, 'ICreateUserData', 'ICreateUserRequest');

  createMap<ICreatePiiIncidentCategoryData, ICreatePiiIncidentCategoryRequest>(
    mapper,
    'ICreatePiiIncidentCategoryData',
    'ICreatePiiIncidentCategoryRequest',
  );

  createMap<ICreateWhitelistRecordData, ICreateWhitelistRecordRequest>(
    mapper,
    'ICreateWhitelistRecordData',
    'ICreateWhitelistRecordRequest',
  );

  createMap<ICreateFileCategoryData, ICreateFileCategoryRequest>(
    mapper,
    'ICreateFileCategoryData',
    'ICreateFileCategoryRequest',
  );

  createMap<ICreateFileCategoryFieldData, ICreateFileCategoryFieldRequest>(
    mapper,
    'ICreateFileCategoryFieldData',
    'ICreateFileCategoryFieldRequest',
  );

  createMap<ICreateFileSubCategoryData, ICreateFileSubCategoryRequest>(
    mapper,
    'ICreateFileSubCategoryData',
    'ICreateFileSubCategoryRequest',
  );

  createMap<ICreateFileSubCategoryMappingData, ICreateFileSubCategoryMappingRequest>(
    mapper,
    'ICreateFileSubCategoryMappingData',
    'ICreateFileSubCategoryMappingRequest',
  );

  createMap<
    ICreateFileSubCategoryTransformationScriptData,
    ICreateFileSubCategoryTransformationScriptRequest
  >(
    mapper,
    'ICreateFileSubCategoryTransformationScriptData',
    'ICreateFileSubCategoryTransformationScriptRequest',
  );

  createMap<IUpdateClientData, IUpdateClientRequest>(
    mapper,
    'IUpdateClientData',
    'IUpdateClientRequest',
  );

  createMap<IUpdateClientRolesDataRole, IUpdateClientRolesRequestRole>(
    mapper,
    'IUpdateClientRolesDataRole',
    'IUpdateClientRolesRequestRole',
  );

  createMap<ICreateProjectData, ICreateProjectRequest>(
    mapper,
    'ICreateProjectData',
    'ICreateProjectRequest',
    forMember(
      (destination) => destination.startDate,
      mapFrom((source) => source.startDate.getTime()),
    ),
  );

  createMap<IUpdateFileUserStatusData, IUpdateFileUserStatusRequest>(
    mapper,
    'IUpdateFileUserStatusData',
    'IUpdateFileUserStatusRequest',
  );

  createMap<IChangeFileCategoryData, IChangeFileCategoryRequest>(
    mapper,
    'IChangeFileCategoryData',
    'IChangeFileCategoryRequest',
  );

  createMap<IChangeFileSubCategoryMappingData, IChangeFileSubCategoryMappingRequest>(
    mapper,
    'IChangeFileSubCategoryMappingData',
    'IChangeFileSubCategoryMappingRequest',
  );

  createMap<
    IChangeFileSubCategoryTransformationScriptData,
    IChangeFileSubCategoryTransformationScriptRequest
  >(
    mapper,
    'IChangeFileSubCategoryTransformationScriptData',
    'IChangeFileSubCategoryTransformationScriptRequest',
  );

  createMap<IUpdateProductData, IUpdateProductRequest>(
    mapper,
    'IUpdateProductData',
    'IUpdateProductRequest',
  );

  createMap<IUpdateFeatureData, IUpdateFeatureRequest>(
    mapper,
    'IUpdateFeatureData',
    'IUpdateFeatureRequest',
  );

  createMap<IUpdateProjectData, IUpdateProjectRequest>(
    mapper,
    'IUpdateProjectData',
    'IUpdateProjectRequest',
    forMember(
      (destination) => destination.startDate,
      mapFrom((source) => (source.startDate ? source.startDate.getTime() : undefined)),
    ),
  );

  createMap<IUpdatePiiIncidentCategoryData, IUpdatePiiIncidentCategoryRequest>(
    mapper,
    'IUpdatePiiIncidentCategoryData',
    'IUpdatePiiIncidentCategoryRequest',
  );

  createMap<IUpdateWhitelistRecordData, IUpdateWhitelistRecordRequest>(
    mapper,
    'IUpdateWhitelistRecordData',
    'IUpdateWhitelistRecordRequest',
  );

  createMap<IUpdateStandardPiiIncidentCategoryData, IUpdateStandardPiiIncidentCategoryRequest>(
    mapper,
    'IUpdateStandardPiiIncidentCategoryData',
    'IUpdateStandardPiiIncidentCategoryRequest',
  );

  createMap<IUpdateFileCategoryData, IUpdateFileCategoryRequest>(
    mapper,
    'IUpdateFileCategoryData',
    'IUpdateFileCategoryRequest',
  );

  createMap<IUpdateFileCategoryFieldData, IUpdateFileCategoryFieldRequest>(
    mapper,
    'IUpdateFileCategoryFieldData',
    'IUpdateFileCategoryFieldRequest',
  );

  createMap<IUpdateFileSubCategoryData, IUpdateFileSubCategoryRequest>(
    mapper,
    'IUpdateFileSubCategoryData',
    'IUpdateFileSubCategoryRequest',
  );

  createMap<IUpdateFileSubCategoryMappingData, IUpdateFileSubCategoryMappingRequest>(
    mapper,
    'IUpdateFileSubCategoryMappingData',
    'IUpdateFileSubCategoryMappingRequest',
  );

  createMap<
    ICreateProjectResultsUploadedNotificationData,
    ICreateProjectResultsUploadedNotificationRequest
  >(
    mapper,
    'ICreateProjectResultsUploadedNotificationData',
    'ICreateProjectResultsUploadedNotificationRequest',
  );

  createMap<IUpdateFileCategoriesOrderData, IUpdateFileCategoriesOrderRequest>(
    mapper,
    'IUpdateFileCategoriesOrderData',
    'IUpdateFileCategoriesOrderRequest',
  );

  createMap<IUpdateAccountsDataAccount, IUpdateAccountsRequestAccount>(
    mapper,
    'IUpdateAccountsDataAccount',
    'IUpdateAccountsRequestAccount',
    forMember(
      (dest) => dest.detailAccounts,
      mapFrom((src) =>
        src.detailAccounts
          ? src.detailAccounts.map((account) =>
              mapper.map(account, 'IUpdateAccountsDataAccount', 'IUpdateAccountsRequestAccount'),
            )
          : undefined,
      ),
    ),
  );

  createMap<IUpdateSubAccountsData, IUpdateSubAccountsRequest>(
    mapper,
    'IUpdateSubAccountsData',
    'IUpdateSubAccountsRequest',
    forMember(
      (dest) => dest.GLAccounts,
      mapFrom((src) =>
        src.GLAccounts.map((account) =>
          mapper.map(
            account,
            'IUpdateSubAccountsDataGLAccount',
            'IUpdateSubAccountsRequestGLAccount',
          ),
        ),
      ),
    ),
    forMember(
      (dest) => dest.GLTotalAccounts,
      mapFrom((src) =>
        src.GLTotalAccounts.map((account) =>
          mapper.map(
            account,
            'IUpdateSubAccountsDataGLAccount',
            'IUpdateSubAccountsRequestGLAccount',
          ),
        ),
      ),
    ),
    forMember(
      (dest) => dest.DMAccounts,
      mapFrom((src) =>
        src.DMAccounts.map((account) =>
          mapper.map(
            account,
            'IUpdateSubAccountsDataDMAccount',
            'IUpdateSubAccountsRequestDMAccount',
          ),
        ),
      ),
    ),
    forMember(
      (dest) => dest.DMTotalAccounts,
      mapFrom((src) =>
        src.DMTotalAccounts.map((account) =>
          mapper.map(
            account,
            'IUpdateSubAccountsDataDMAccount',
            'IUpdateSubAccountsRequestDMAccount',
          ),
        ),
      ),
    ),
    forMember(
      (dest) => dest.SubAccounts,
      mapFrom((src) =>
        src.SubAccounts.map((account) =>
          mapper.map(
            account,
            'IUpdateSubAccountsDataSubAccount',
            'IUpdateSubAccountsRequestSubAccount',
          ),
        ),
      ),
    ),
  );

  createMap<IUpdateNewTypeCodeData, IUpdateNewTypeCodeRequest>(
    mapper,
    'IUpdateNewTypeCodeData',
    'IUpdateNewTypeCodeRequest',
  );
};
