import { BalancingItem, IBalancingItem } from './components/BalancingItem';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { getAccounts } from '@services/api';
import useStyles from './styles';

interface IProps {
  project: IProject;
}

const assetsItem: IBalancingItem = {
  title: 'Assets',
  type: -1,
  sourceBalance: 877876.46,
  adjustments: -78807.73,
  fees: 10263.86,
  totalBalance: 809332.59,
  items: [
    {
      title: 'Loans',
      type: 0,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
    {
      title: 'Allowance for Loan Loss',
      type: 91,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
    {
      title: 'Investments',
      type: 1,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
    {
      title: 'Non-Interest Assets',
      type: 2,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
  ],
};

const liabilitiesAndNetWorthItem: IBalancingItem = {
  title: 'Liabilities and Net Worth',
  type: -1,
  sourceBalance: 877876.46,
  adjustments: -78807.73,
  fees: 10263.86,
  totalBalance: 809332.59,
  items: [
    {
      title: 'Deposits',
      type: -1,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
      items: [
        {
          title: 'Non-Maturity Deposits',
          type: 5,
          sourceBalance: 877876.46,
          adjustments: -78807.73,
          fees: 10263.86,
          totalBalance: 809332.59,
        },
        {
          title: 'Term Deposits',
          type: 9,
          sourceBalance: 877876.46,
          adjustments: -78807.73,
          fees: 10263.86,
          totalBalance: 809332.59,
        },
      ],
    },
    {
      title: 'Borrowings',
      type: 4,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
    {
      title: 'Non-Int Liabilities',
      type: 3,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
    {
      title: 'Net Worth',
      type: 92,
      sourceBalance: 877876.46,
      adjustments: -78807.73,
      fees: 10263.86,
      totalBalance: 809332.59,
    },
  ],
};

export const BalancingTab = ({ project }: IProps) => {
  const { classes } = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  useEffect(() => {
    if (project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
  }, [project.links[Actions.getAccounts]]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  const showLoader = useLoader(getAccountsLoading);

  return (
    <Box className={classes.root}>
      <BalancingItem project={project} item={assetsItem} main accounts={accounts} />
      <BalancingItem project={project} item={liabilitiesAndNetWorthItem} main accounts={accounts} />
      <Loader show={showLoader} />
    </Box>
  );
};
